export const handleRes = async (res) => {
  if (!res) return;
  if (res.ok) {
    const data = await res.json();
    return data;
  } else {
    const error = (await res) && res.json();
    if (error?.name === "CompanyNotOwned")
      return window.alert("You don't have the right permission to perform this action.");
    console.error(error);
  }
};
