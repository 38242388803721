import { createContext, useCallback, useEffect, useState } from "react";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";

export const PaiementContext = createContext();

const { Provider } = PaiementContext;

export const PaiementProvider = (props) => {
  const [offers, setOffers] = useState();

  // API CALL - GET OFFERS / PACKS
  const getOffers = useCallback(async () => {
    const res = await fetchData("product", undefined, "get");
    const data = await handleRes(res);
    data && setOffers(data);
  }, []);

  // API CALL - GET PAIEMENT / STRIPE
  const getPaiement = useCallback(
    async (companyId, products, succesUrl, entityType, entityId) => {
      const productsPath = products.map((product) => `&products=${product}`);

      const res = await fetchData(
        `payment/url?companyId=${companyId}&successUrl=${succesUrl}&cancelUrl=${encodeURI(
          window.location.href
        )}${productsPath.join("")}${entityType ? `&entityType=${entityType}` : ""}${
          entityId ? `&entityId=${entityId}` : ""
        }`,
        undefined,
        "get"
      );
      return handleRes(res);
    },
    []
  );

  useEffect(() => getOffers(), [getOffers]);

  const value = { getOffers, offers, getPaiement };

  return <Provider value={value}>{props.children}</Provider>;
};
