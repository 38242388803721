import { createContext, useCallback, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";
import { CompagnyContext } from "./CompagnyContext";

export const WebsiteContext = createContext();

const TOTAL_HIGHLIGHTED_WEBSITE = 12;
const TOTAL_LAST_UPDATED_WEBSITE = 12;

const { Provider } = WebsiteContext;

export const WebsiteProvider = (props) => {
  const [websiteHightLighted, setWebsiteHightLighted] = useState(undefined);
  const [lastUpdatedWebsite, setLastUpdatedWebsite] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [categoriesShowedHome, setCategoriesShowedHome] = useState([]);
  const [website, setWebsite] = useState(undefined);

  const { getCompanyWebsite } = useContext(CompagnyContext);
  const { i18n } = useTranslation();

  // API CALL - GET CATEGORIES
  useEffect(() => {
    const getWebsiteCategories = async () => {
      const res = await fetchData(`website/categories`, undefined, "GET");
      const cats = await handleRes(res);
      cats && setCategories(cats);
    };

    getWebsiteCategories();
  }, []);

  // API CALL - CREATE WEBSITE
  const createWebsite = useCallback(async (formData) => {
    const res = await fetchData("website", formData);
    return handleRes(res);
  }, []);

  // API CALL - UPDATE WEBSITE
  const updateWebsite = useCallback(async (formData, websiteId) => {
    const res = await fetchData(`website/${websiteId}`, formData);
    return handleRes(res);
  }, []);

  // API CALL - SEARCH WEBSITE
  const searchWebsites = useCallback(
    async (q, category, city, limit = 100, signal, highlighted = false, page = 1) => {
      const res = await fetchData(
        `website/search?q=${q ? q : ""}&categories=${category ? category : ""}&city=${
          city ? city : ""
        }&limit=${limit}&page=${page}&lang=${i18n?.language?.toLowerCase()}&status=validated${
          highlighted ? "&highlighted=true" : ""
        }`,
        undefined,
        "GET",
        signal
      );
      return handleRes(res);
    },
    [i18n]
  );

  // API CALL - GET WEBSITE BY ID
  const getWebsiteById = useCallback(async (id, signal, pages) => {
    const res = await fetchData(
      `website/${id}${pages ? "?pages=1" : ""}`,
      undefined,
      "GET",
      signal
    );
    const data = await handleRes(res);
    setWebsite(data);
    return data;
  }, []);

  // API CALL - GET CATEGORIES WITH WEBSITE COUNT
  const countCategoriesWebsite = useCallback(
    async (cats, lang, signal) => {
      if (categoriesShowedHome && categoriesShowedHome?.length > 0) return;
      const cat = cats
        .map((cat) => "categories[]=" + cat + "&")
        .join()
        .replace(/,/g, "");

      const res = await fetchData(
        `website/categoryCount?${cat}&lang=${lang.toLowerCase()}`,
        undefined,
        "GET",
        signal
      );
      const data = await handleRes(res);
      return setCategoriesShowedHome(data);
    },
    [categoriesShowedHome]
  );

  // API CALL - GET WEBSITE HIGHTLIGHTED
  const getWebsiteHightlighted = useCallback(
    async (signal) => {
      const data = await searchWebsites(
        null,
        null,
        null,
        TOTAL_HIGHLIGHTED_WEBSITE,
        signal,
        true
      );
      data && setWebsiteHightLighted(data);
      return;
    },
    [searchWebsites]
  );

  // API CALL - GET LAST UPDATED WEBSITE
  const getLastUpdatedWebsite = useCallback(
    async (signal) => {
      const data = await searchWebsites(
        null,
        null,
        null,
        TOTAL_LAST_UPDATED_WEBSITE,
        signal
      );
      data && setLastUpdatedWebsite(data);
      return;
    },
    [searchWebsites]
  );

  // API CALL - CHANGE WEBSITE STATUS
  const changeWebsiteStatus = useCallback(async (websiteId, status) => {
    const res = await fetchData(
      `website/${websiteId}/status`,
      { status: status },
      "POST"
    );
    return handleRes(res);
  }, []);

  // DEACTIVATE WEBSITE
  const deactivateWebsite = useCallback(
    async (website) => {
      if (website.status === "deactivated") return;
      if (window.confirm("Are you sure you want to deactivate the website ?")) {
        await changeWebsiteStatus(website.id, "deactivated");
        await getCompanyWebsite(website.companyId);
      }
    },
    [changeWebsiteStatus, getCompanyWebsite]
  );

  const value = {
    createWebsite,
    updateWebsite,
    searchWebsites,
    categoriesShowedHome,
    categories,
    getWebsiteById,
    countCategoriesWebsite,
    websiteHightLighted,
    getWebsiteHightlighted,
    deactivateWebsite,
    getLastUpdatedWebsite,
    lastUpdatedWebsite,
    website,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
