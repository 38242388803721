const hostname = window.location.hostname;

const config = {
  url: "http://localhost:3001/",
  recaptcha: {
    siteKey: "6LeP-6ocAAAAAMg0WO11KcnUl23bg6q8c3LPm90i",
  },
  environment: "development",
};

// Production
if (
  hostname === "1top-search.com" ||
  hostname === "fr.1top-search.com" ||
  hostname === "es.1top-search.com" ||
  hostname === "en.1top-search.com"
) {
  config.url = "https://directory.sbfs.io/";
  config.environment = "production";
}

// Staging
if (
  hostname === "staging.1top-search.com" ||
  hostname === "staging-fr.1top-search.com" ||
  hostname === "staging-en.1top-search.com" ||
  hostname === "staging-es.1top-search.com"
) {
  config.url = "https://directory-staging.sbfs.io/";
  config.environment = "staging";
}

export default config;
