import "./index.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import config from "./config";
import { Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { UserProvider } from "./context/UserContext";
import { CompagnyProvider } from "./context/CompagnyContext";
import { WebsiteProvider } from "./context/WebsiteContext";
import { PageProvider } from "./context/PageContext";
import { PaiementProvider } from "./context/PaiementContext";
import { AdminProvider } from "./context/AdminContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";
import PageLoader from "./components/pageLoader/PageLoader";

const history = createBrowserHistory();

const tagManagerArgs = { gtmId: "GTM-PWWMCLL" };

if (config.environment === "production") TagManager.initialize(tagManagerArgs);

Sentry.init({
  environment: config.environment,

  dsn: "https://4647fbd387f640218c496a18344af38e@o369240.ingest.sentry.io/6208554",

  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: config.environment === "production" ? 0.1 : 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha.siteKey}>
      <UserProvider>
        <CompagnyProvider>
          <WebsiteProvider>
            <PageProvider>
              <PaiementProvider>
                <Suspense fallback={<PageLoader />}>
                  <Router history={history}>
                    <AdminProvider>
                      <App />
                    </AdminProvider>
                  </Router>
                </Suspense>
              </PaiementProvider>
            </PageProvider>
          </WebsiteProvider>
        </CompagnyProvider>
      </UserProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
