import { lazy } from "react";

const Home = lazy(() => import("../pages/home/Home"));
const OrderSummary = lazy(() => import("../pages/orderSummary/OrderSummary"));
const UpdatePage = lazy(() => import("../pages/updatePage/UpdatePage"));
const Categories = lazy(() => import("../pages/categories/Categories"));
const About = lazy(() => import("../pages/about/About"));
const Search = lazy(() => import("../pages/search/SearchPage"));
const Faq = lazy(() => import("../pages/faq/Faq"));
const Privacy = lazy(() => import("../pages/privacy/Privacy"));
const Terms = lazy(() => import("../pages/terms/Terms"));
const Legal = lazy(() => import("../pages/legal/Legal"));
const Login = lazy(() => import("../pages/login/Login"));
const Register = lazy(() => import("../pages/register/Register"));
const Contact = lazy(() => import("../pages/contact/Contact"));
const Website = lazy(() => import("../pages/website/Website"));
const ResetPassword = lazy(() => import("../pages/resetPassword/ResetPassword"));
const Offers = lazy(() => import("../pages/offer/Offers"));
const ManageCompany = lazy(() => import("../pages/manageCompany/ManageCompany"));
const ManageWebsite = lazy(() => import("../pages/manageWebsite/ManageWebsite"));
const Account = lazy(() => import("../pages/account/Account"));
const UpdateWebsite = lazy(() => import("../pages/updateWebsite/UpdateWebsite"));
const UpdateCompany = lazy(() => import("../pages/updateCompany/UpdateCompany"));
const AddWebsite = lazy(() => import("../pages/addWebsite/AddWebsite"));
const AddCompagny = lazy(() => import("../pages/addCompagny/AddCompagny"));
const AddPage = lazy(() => import("../pages/addPage/AddPage"));
const PaiementConfirmation = lazy(() =>
  import("../pages/paiementConfirmation/PaiementConfirmation")
);
const PaiementError = lazy(() => import("../pages/paiementError/PaiementError"));
const SelectOffer = lazy(() => import("../pages/selectOffer/SelectOffer"));
const Admin = lazy(() => import("../pages/admin/Admin"));
const ManageOffer = lazy(() => import("../pages/manageOffer/ManageOffer"));

const routes = [
  {
    path: "/my_account/manage_website/:id/update_page/:pageId/",
    component: UpdatePage,
    hidden: true,
  },
  { path: "/my_account/manage_website/:id/add_page", component: AddPage, hidden: true },
  {
    path: "/my_account/manage_website/:id/update",
    component: UpdateWebsite,
    hidden: true,
  },
  { path: "/my_account/manage_website/:id/", component: ManageWebsite, hidden: true },
  {
    path: "/my_account/manage_company/:id/select_offers",
    component: SelectOffer,
    hidden: true,
  },
  {
    path: "/my_account/manage_company/:id/manage_offers",
    component: ManageOffer,
    hidden: true,
  },
  {
    path: "/my_account/manage_company/:id/add_website",
    component: AddWebsite,
    hidden: true,
  },
  {
    path: "/my_account/manage_company/:id/update",
    component: UpdateCompany,
    hidden: true,
  },
  { path: "/my_account/manage_company/:id/", component: ManageCompany, hidden: true },
  { path: "/my_account/add_company", component: AddCompagny, hidden: true },
  { path: "/my_account", component: Account, hidden: true },
  { path: "/order_summary", component: OrderSummary, hidden: true },
  { path: "/payment_confirmation", component: PaiementConfirmation, hidden: true },
  { path: "/payment_error", component: PaiementError, hidden: true },
  { path: "/admin", component: Admin, secured: true },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/about", component: About },
  { path: "/search", component: Search },
  { path: "/offers", component: Offers },
  { path: "/contact", component: Contact },
  { path: "/website/:id/", component: Website },
  { path: "/categories/:name", component: Categories },
  { path: "/categories", component: Categories },
  { path: "/reset_password", component: ResetPassword },
  { path: "/legal", component: Legal },
  { path: "/terms_of_use", component: Terms },
  { path: "/privacy", component: Privacy },
  { path: "/faq", component: Faq },
  { path: "/", component: Home },
];

export default routes;
