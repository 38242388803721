import { createContext, useCallback, useContext, useState } from "react";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";
import { UserContext } from "./UserContext";

export const CompagnyContext = createContext();

const { Provider } = CompagnyContext;

export const CompagnyProvider = (props) => {
  const [companyWebsites, setCompanyWebsites] = useState(undefined);
  const { getUserCompagnies } = useContext(UserContext);

  // API CALL - CREATE COMPANY
  const createCompany = useCallback(async (formData) => {
    const res = await fetchData("company", formData);
    return handleRes(res);
  }, []);

  // API CALL - UPDATE COMPANY
  const updateCompany = useCallback(async (formData, companyId) => {
    const res = await fetchData(`company/${companyId}`, formData);
    return handleRes(res);
  }, []);

  // APU CALL - ADD USER TO COMPANY
  const addUserToCompany = useCallback(async (userEmail, companyId) => {
    const res = await fetchData(`company/${companyId}/user`, { email: userEmail });
    return res;
  }, []);

  // API CALL - GET COMPANY BY ID
  const getCompanyById = useCallback(async (companyId, signal) => {
    const res = await fetchData(`company/${companyId}`, undefined, "GET", signal);
    return handleRes(res);
  }, []);

  // API CALL - GET COMPANY WEBSITES
  const getCompanyWebsite = useCallback(async (companyId, signal, pages) => {
    const res = await fetchData(
      `company/${companyId}/website${pages ? "?pages=1" : ""}`,
      undefined,
      "GET",
      signal
    );
    const data = await handleRes(res);
    setCompanyWebsites(data);
  }, []);

  // API CALL - GET COMPANY SUBSCRIPTION
  const getCompanySubscription = useCallback(async (companyID, signal) => {
    const res = await fetchData(
      `company/${companyID}/subscription`,
      undefined,
      "GET",
      signal
    );
    return handleRes(res);
  }, []);

  // API CALL - GET COMPANY CREDIT
  const getCompanyCredit = useCallback(async (companyID, signal) => {
    const res = await fetchData(`company/${companyID}/credit`, undefined, "GET", signal);
    return handleRes(res);
  }, []);

  // API CALL - GET COMPANY INVOICES
  const getCompanyInvoice = useCallback(async (companyID, signal) => {
    const res = await fetchData(`company/${companyID}/payment`, undefined, "GET", signal);
    return handleRes(res);
  }, []);

  // API CALL - GET COMPANY INVOICES
  const unsubscribe = useCallback(async (companyId, subscriptionId, signal) => {
    const res = await fetchData(
      `company/${companyId}/subscription/${subscriptionId}/delete`,
      undefined,
      "POST",
      signal
    );
    return handleRes(res);
  }, []);

  // API CALL - CHANGE COMPANY STATUS
  const changeCompanyStatus = useCallback(async (companyId, status) => {
    const res = await fetchData(
      `company/${companyId}/status`,
      { status: status },
      "POST"
    );
    return handleRes(res);
  }, []);

  // DELETE COMPANY
  const deleteCompany = useCallback(
    async (company) => {
      if (company.status === "deleted") return;
      if (window.confirm("Are you sure you want to delete the company ?")) {
        const res = await changeCompanyStatus(company.id, "deleted");
        res && (await getUserCompagnies());
        return res;
      }
    },
    [changeCompanyStatus, getUserCompagnies]
  );

  const value = {
    createCompany,
    updateCompany,
    addUserToCompany,
    getCompanyById,
    getCompanyWebsite,
    changeCompanyStatus,
    getCompanySubscription,
    getCompanyCredit,
    getCompanyInvoice,
    unsubscribe,
    deleteCompany,
    companyWebsites,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
