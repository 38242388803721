import "./LanguageSwitch.scss";
import flagFr from "../../img/flag/fr.svg";
import flagEs from "../../img/flag/es.svg";
import flagEn from "../../img/flag/uk.svg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import LanguageSwitchFunction from "./LanguageSwitchFunction";
import { withRouter } from "react-router-dom";

const LanguageSwitch = ({ setMenuLangOpen, menuLangOpen }) => {
  const { handleLangChange, i18n } = LanguageSwitchFunction(
    setMenuLangOpen,
    menuLangOpen
  );

  return (
    <div className="language-switch">
      <div className="language-switch__container">
        <ul
          className={
            menuLangOpen
              ? "language-switch__dropDown language-switch__dropDown--open"
              : "language-switch__dropDown"
          }>
          <li
            onClick={() => handleLangChange("en")}
            className={
              i18n.language === "en"
                ? "language-switch__item language-switch__item--active"
                : "language-switch__item"
            }>
            <img src={flagEn} value="en" alt="English flag" />
          </li>
          <li
            onClick={() => handleLangChange("fr")}
            className={
              i18n.language === "fr"
                ? "language-switch__item language-switch__item--active"
                : "language-switch__item"
            }>
            <img src={flagFr} alt="French flag" />
          </li>
          <li
            onClick={() => handleLangChange("es")}
            className={
              i18n.language === "es"
                ? "language-switch__item language-switch__item--active"
                : "language-switch__item"
            }>
            <img src={flagEs} alt="Spanish flag" />
          </li>
        </ul>
      </div>
      <div
        className={
          menuLangOpen
            ? "language-switch__arrow language-switch__arrow--open"
            : "language-switch__arrow"
        }
        onClick={() => setMenuLangOpen(!menuLangOpen)}>
        <MdOutlineKeyboardArrowDown />
      </div>
    </div>
  );
};

export default withRouter(LanguageSwitch);
