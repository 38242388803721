import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Cookies.scss";
import { MdOutlineClose } from "react-icons/md";
import { useState, useEffect } from "react";

const Cookies = () => {
  const { t } = useTranslation();
  const [isClose, setIsClose] = useState(true);

  useEffect(() => {
    const isClose = window.sessionStorage.getItem("cookiesIsClose");
    if (!isClose) setIsClose(false);
  }, []);

  const handleClose = () => {
    setIsClose(true);
    window.sessionStorage.setItem("cookiesIsClose", true);
  };

  return (
    <div className={`Cookies ${isClose ? "Cookies--close" : ""}`}>
      <p>
        {t("cookies.text")} <Link to={t("link.legal")}> {t("cookies.btn")}</Link>
      </p>
      <MdOutlineClose className="Cookies__close" onClick={handleClose} />
    </div>
  );
};

export default Cookies;
