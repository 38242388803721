import "./Footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../img/header/logo2.png";
import HeaderNavigation from "../header-navigation/HeaderNavigation";
import Container from "../container/Container";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Container>
        <img className="footer__logo" width="292" height="80" src={logo} alt="logo" />
        <div className="footer__nav">
          <HeaderNavigation />
        </div>
        <Link className="footer__contact" to={t("link.contact")}>
          {t("btn.contactUs")}
        </Link>
      </Container>
      <ul className="footer__nav2">
        <li className="footer__nav2-item">
          <Link to={t("link.legal")}> {t("footer.legal")}</Link>
        </li>
        <li className="footer__nav2-item">
          <Link to={t("link.terms")}> {t("footer.terms")}</Link>
        </li>
        <li className="footer__nav2-item">
          <Link to={t("link.privacy")}> {t("footer.privacy")}</Link>
        </li>
        <li className="footer__nav2-item">
          <Link to={t("link.faq") || "Loading..."}>{t("footer.faq")}</Link>
        </li>
      </ul>
      <p className="footer__copyright">© Subforce {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
