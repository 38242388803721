import "./HeaderNavigation.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getItem } from "../../helpers/localStorage";

const HeaderNavigation = () => {
  const { t } = useTranslation();

  return (
    <nav className="header-navigation">
      <ul className="header-navigation__list">
        <li className="header-navigation__link-item">
          <Link to="/">{t("header.home")}</Link>
        </li>
        <li className="header-navigation__link-item">
          <Link to={t("link.categories")}>{t("header.categories")}</Link>
        </li>
        <li className="header-navigation__link-item">
          <Link to={t("link.about")}>{t("header.about")}</Link>
        </li>
        <li className="header-navigation__link-item">
          <Link to={t("link.offers")}>{t("header.offers")}</Link>
        </li>
        {!getItem("user_token") && (
          <li className="header-navigation__link-item">
            <Link to={t("link.login")}>{t("header.connection")}</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
