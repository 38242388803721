import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import resources from "./locales";

export const setInitialLang = () => {
  let lang;

  if (window.location.hostname !== "localhost") {
    const href = window.location.href;
    const splitedHref = href.split(".");
    if (splitedHref[0].includes("es")) lang = "es";
    if (splitedHref[0].includes("fr")) lang = "fr";
    if (splitedHref[0].includes("en")) lang = "en";
  }

  if (!lang) lang = "en";

  return lang;
};

i18n.use(initReactI18next).init({
  debug: false,
  lng: setInitialLang(),
  fallbackLng: "en",
  locales: ["en", "fr", "es"],
  keySeparator: ".",
  interpolation: { escapeValue: false },
  resources: resources,
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
