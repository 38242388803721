import config from "./../config";
import { getItem } from "./localStorage";

export const fetchData = async (path, data, method, signal, token) => {
  if (!path) return console.error("Missing path ");

  let objectProperties = {};

  objectProperties.headers = {
    authorization: token ? token : "Bearer " + getItem("user_token"),
    "Content-Type": "application/json",
  };

  objectProperties.method = method || "POST";

  if (data) objectProperties.body = JSON.stringify(data);

  if (signal) objectProperties.signal = signal;

  try {
    return await fetch(`${config.url}${path}`, objectProperties);
  } catch (error) {
    console.error(error);
  }
};
