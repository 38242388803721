import { Redirect, Route } from "react-router-dom";
import { getItem } from "../../helpers/localStorage";

const SecuredRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getItem("user")?.roles?.includes("admin") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default SecuredRoute;
