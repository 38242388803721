import { Redirect, Route } from "react-router";
import { getItem } from "../../helpers/localStorage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (getItem("user") ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};

export default PrivateRoute;
