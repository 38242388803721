import { createContext, useCallback } from "react";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";

export const AdminContext = createContext();

const { Provider } = AdminContext;

export const AdminProvider = (props) => {
  const status = [
    "validated",
    "pending",
    "pending-fast",
    "denied",
    "deactivated",
    "deleted",
  ];

  // API CALL - SEARCH COMPANIES
  const searchCompanies = useCallback(async (query, limit, page, status, signal) => {
    const res = await fetchData(
      `company/search?q=${query ? query : ""}&page=${page ? page : 1}&limit=${
        limit ? limit : ""
      }&status=${status ? status : ""}`,
      undefined,

      "GET",
      signal
    );
    return handleRes(res);
  }, []);

  // API CALL - SEARCH USERS
  const searchUsers = useCallback(async (query, limit, page) => {
    const res = await fetchData(
      `user/search?q=${query ? query : ""}&page=${page ? page : 1}&limit=${
        limit ? limit : ""
      }`,
      undefined,
      "GET"
    );
    return handleRes(res);
  }, []);

  // API CALL - SEARCH PAGES
  const searchPages = useCallback(async (query, limit, page, status) => {
    const res = await fetchData(
      `page/search?q=${query ? query : ""}&page=${page ? page : 1}&limit=${
        limit ? limit : ""
      }&status=${status ? status : ""}`,
      undefined,
      "GET"
    );
    return handleRes(res);
  }, []);

  // API CALL - SEARCH WEBSITE
  const searchWebsites = useCallback(async (query, limit, page, status, highlighted) => {
    const res = await fetchData(
      `website/search?q=${query ? query : ""}&page=${page ? page : 1}&limit=${
        limit ? limit : ""
      }&status=${status ? status : ""}${highlighted ? "&highlighted=true" : ""}`,
      undefined,
      "GET"
    );
    return handleRes(res);
  }, []);

  // API CALL - CHANGE WEBSITE STATUS
  const changeWebsiteStatus = useCallback(async (websiteId, status) => {
    const res = await fetchData(
      `website/${websiteId}/status`,
      { status: status },
      "POST"
    );
    return handleRes(res);
  }, []);

  // API CALL - CHANGE COMPANY STATUS
  const changeCompanyStatus = useCallback(async (companyId, status) => {
    const res = await fetchData(
      `company/${companyId}/status`,
      { status: status },
      "POST"
    );
    return handleRes(res);
  }, []);

  // API CALL 6 CHANGE PAGE STATUS
  const changePageStatus = useCallback(async (pageId, status) => {
    const res = await fetchData(`page/${pageId}/status`, { status: status }, "POST");
    return handleRes(res);
  }, []);

  const value = {
    changeWebsiteStatus,
    changeCompanyStatus,
    status,
    changePageStatus,
    searchUsers,
    searchCompanies,
    searchPages,
    searchWebsites,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
