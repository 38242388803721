import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { UserContext } from "./../../context/UserContext";

const HeaderFunctions = () => {
  const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);
  const [menuLangOpen, setMenuLangOpen] = useState(false);
  const { user, userLogOut } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => setMenuMobileIsOpen(false), [location.pathname]);

  useEffect(() => {
    const body = document.querySelector("body");

    menuMobileIsOpen ? (body.style.overflow = "hidden") : (body.style.overflow = "auto");
  }, [menuMobileIsOpen]);

  const handleLogOut = () => {
    userLogOut();
    history.push("/");
  };

  const handleMenuClose = () => {
    setMenuLangOpen(false);
    setMenuMobileIsOpen(!menuMobileIsOpen);
  };

  return {
    handleMenuClose,
    handleLogOut,
    t,
    user,
    menuLangOpen,
    menuMobileIsOpen,
    setMenuLangOpen,
    setMenuMobileIsOpen,
  };
};

export default HeaderFunctions;
