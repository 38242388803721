import { createContext, useCallback, useContext } from "react";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";
import { WebsiteContext } from "./WebsiteContext";

export const PageContext = createContext();

const { Provider } = PageContext;

export const PageProvider = (props) => {
  const { getWebsiteById } = useContext(WebsiteContext);

  // API CALL - CREATE PAGE
  const createPage = useCallback(async (formData) => {
    const res = await fetchData(`page`, formData, "POST");
    return handleRes(res);
  }, []);

  // API CALL - UPDATE PAGE
  const updatePage = useCallback(async (formData, id) => {
    const res = await fetchData(`page/${id}`, formData, "POST");
    return handleRes(res);
  }, []);

  // API CALL - GET PAGE BY ID
  const getPageById = useCallback(async (id, signal) => {
    const res = await fetchData(`page/${id}`, undefined, "GET", signal);
    return handleRes(res);
  }, []);

  // API CALL - CHANGE PAGE STATUS
  const changePageStatus = useCallback(async (pageId, status) => {
    const res = await fetchData(`page/${pageId}/status`, { status: status }, "POST");
    return handleRes(res);
  }, []);

  // DEACTIVATE PAGE
  const deactivatePage = useCallback(
    async (page) => {
      if (page.status === "deactivated") return;
      if (window.confirm("Are you sure you want to deactivate the page ?")) {
        await changePageStatus(page.id, "deactivated");
        await getWebsiteById(page.websiteId, null, true);
      }
    },
    [changePageStatus, getWebsiteById]
  );

  const value = { createPage, updatePage, getPageById, deactivatePage };

  return <Provider value={value}>{props.children}</Provider>;
};
