import { createContext, useCallback, useState, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { fetchData } from "../helpers/fetch";
import { handleRes } from "../helpers/handleRes";
import { getItem, setItem } from "../helpers/localStorage";
const TOKEN_TTL = 60 * 60 * 24;

export const UserContext = createContext();

const { Provider } = UserContext;

export const UserProvider = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [userCompagnies, setUserCompagnies] = useState([]);

  // GET USER STORE IN LOCAL STORAGE
  const getUserStorage = useCallback(async () => {
    const user = await getItem("user");
    setUser(user);
  }, []);

  useEffect(() => getUserStorage(), [getUserStorage]);

  // API CALL - GET USER INFORMAATIONS
  const getUserInfo = useCallback(async () => {
    const res = await fetchData("me", undefined, "GET");
    const data = await handleRes(res);
    data && setItem("user", data, TOKEN_TTL);
  }, []);

  // API CALL - USER REGISTER
  const userRegister = useCallback(
    async (formData) => {
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha("register");
      const response = await fetchData("register", formData, undefined, undefined, token);
      return response;
    },
    [executeRecaptcha]
  );

  // API CALL - USER LOGIN
  const userLogin = useCallback(
    async (formData) => {
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha("login");
      const res = await fetchData("login", formData, undefined, undefined, token);

      if (!res) return;

      if (res && res.ok) {
        const user = await res.json();
        setItem("user_token", user?.tokenId, TOKEN_TTL);
        await getUserInfo();
        return setUser(user);
      } else {
        const error = await res.json();
        return error.name;
      }
    },
    [executeRecaptcha, getUserInfo]
  );

  // USER LOG OUT
  const userLogOut = useCallback(() => {
    setItem("user", null);
    setItem("user_token", null);
    setUser(undefined);
    return;
  }, []);

  // VALIDATE EMAIL
  const validateEmail = useCallback((email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }, []);

  // API CALL - UPDATE USER INFO
  const updateUserInfo = useCallback(async (formData) => {
    const res = await fetchData("me", formData);
    return handleRes(res);
  }, []);

  // API CALL - RECOVER PASSWORD - SENDING EMAIL LINK
  const recoverPassword = useCallback(
    async (email) => {
      if (!executeRecaptcha) return;
      setLoading(true);
      const token = await executeRecaptcha("recover_password");
      const response = await fetchData(
        "password/recover",
        {
          email: email,
        },
        null,
        null,
        token
      );

      setLoading(false);
      return response;
    },
    [executeRecaptcha]
  );

  // API CALL - UPDATE PASSWORD
  const updateUserPassword = useCallback(async (formData, token) => {
    setLoading(true);
    const res = await fetchData("me", formData, null, null, `Bearer ${token}`);
    setLoading(false);
    return handleRes(res);
  }, []);

  // API CALL - GET COMPANY FROM USER
  const getUserCompagnies = useCallback(async (signal) => {
    const res = await fetchData(`me/company`, undefined, "GET", signal);
    const data = await handleRes(res);
    data && setUserCompagnies(data);
  }, []);

  const value = {
    userRegister,
    userLogin,
    user,
    userLogOut,
    loading,
    validateEmail,
    updateUserInfo,
    getUserInfo,
    recoverPassword,
    updateUserPassword,
    getUserCompagnies,
    userCompagnies,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
