import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { WebsiteContext } from "../../context/WebsiteContext";

const LanguageSwitchFunction = (setMenuLangOpen) => {
  const { i18n } = useTranslation();
  const { categories } = useContext(WebsiteContext);
  const location = useLocation();

  useEffect(() => setMenuLangOpen(false), [location.pathname, setMenuLangOpen]);

  const handleLangChange = (lang) => {
    console.log(i18n.language, lang);
    if (i18n.language === lang) return;

    const currentPathname = window.location.pathname;

    if (
      currentPathname.split("/")[1] === "website"
    ) {
      window.location.href = window.location.origin.replace(
        `${i18n.language}.`,
        `${lang}.`
      );
      return;
    }

    const routes = i18n.getResourceBundle(i18n.language, "translations");

    const id = currentPathname.split("/")[3];
    const pageId = currentPathname.split("/")[5];
    const category = currentPathname.split("/")[2];

    if (currentPathname === "/") {
      const newRoute = window.location.href.replace(
        `${i18n.language}.`,
        `${lang}.`
      );
      window.location.href = newRoute;
    }

    const categoryItem = categories.find(
      (item) => item[i18n.language].label.toLowerCase() === decodeURI(category)
    );

    const currentRouteKey = Object.keys(routes.link).find((key) => {
      return (
        decodeURI(
          routes.link[key]
            .replace(":id", id)
            .replace(":pageId", pageId)
            .replace(":name", category)
        ) === decodeURI(currentPathname)
      );
    });

    const newRoutes = i18n.getResourceBundle(lang, "translations");

    const newPath = newRoutes.link[currentRouteKey.replace("/", "")];

    const langBeforeChange = i18n.language;

    window.location.href =
      window.location.origin.replace(`${langBeforeChange}.`, `${lang}.`) +
      newPath
        .replace(":id", id)
        .replace(":pageId", pageId)
        .replace(":name", categoryItem?.[lang]?.label?.toLowerCase());
  };

  return { handleLangChange, i18n };
};

export default LanguageSwitchFunction;
