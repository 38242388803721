import "./Container.scss";

const Container = ({ children, style, className }) => {
  return (
    <div style={style} className={`Container ${className}`}>
      {children}
    </div>
  );
};

export default Container;
