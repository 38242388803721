import { ENGLISH } from "./en/translation";
import { SPANISH } from "./es/translation";
import { FRENCH } from "./fr/translation";

const resources = {
  en: {
    translations: ENGLISH,
  },
  fr: {
    translations: FRENCH,
  },
  es: {
    translations: SPANISH,
  },
};

export default resources;
