import "./PageLoader.scss";
import { useTranslation } from "react-i18next";

const PageLoader = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <div className="pageLoader" {...rest}>
      <div className="pageLoader__container">
        <div className="pageLoader__loader"></div>
      </div>
      <p>{t("pageLoader")}</p>
    </div>
  );
};

export default PageLoader;
