import "./App.scss";
import "../../i18n/i18n";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Cookies from "../cookies/Cookies";
import Routes from "../../routes/Routes";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Routes />
      <Footer />
      <Cookies />
    </div>
  );
};

export default App;
