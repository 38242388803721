import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import PrivateRoute from "../components/privateRoute/PrivateRoute";
import SecuredRoute from "../components/securedRoute/SecuredRoute";
import routes from "./index";

export default function Routes() {

  return (
    <Switch>
      {routes.map(({ path, component, hidden, secured }, key) => {
        if (secured)
          return (
            <SecuredRoute key={key} exact component={component} path={path} />
          );
        if (hidden)
          return (
            <PrivateRoute key={key} exact component={component} path={path} />
          );
        else return <Route exact path={path} component={component} key={key} />;
      })}
      <Redirect to="/" />
    </Switch>
  );
}
