import "./Header.scss";
import logo from "../../img/header/logo2.png";
import { Link } from "react-router-dom";
import { BiMenu, BiX } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import HeaderFunctions from "./HeaderFunctions";
import HeaderNavigation from "../header-navigation/HeaderNavigation";
import Container from "../container/Container";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import { RiUserSettingsFill } from "react-icons/ri";
import { getItem } from "../../helpers/localStorage";

const Header = () => {
  const {
    handleMenuClose,
    handleLogOut,
    menuLangOpen,
    menuMobileIsOpen,
    setMenuLangOpen,
    setMenuMobileIsOpen,
    t,
  } = HeaderFunctions();

  return (
    <div className="header">
      <Container>
        <div className="header__container">
          <Link to="/" className="header__logo">
            <img src={logo} width="292" height="80" alt="logo top search" />
          </Link>
          <div
            className={
              menuMobileIsOpen
                ? "header__wrapper header__wrapper--open"
                : "header__wrapper"
            }>
            <HeaderNavigation />
            <LanguageSwitch
              setMenuLangOpen={setMenuLangOpen}
              menuLangOpen={menuLangOpen}
            />

            <div className="header__userBtns">
              {getItem("user_token") && (
                <Link to={t("link.account")} aria-label="account">
                  <FaUser />
                </Link>
              )}
              {getItem("user")?.roles?.includes("admin") && (
                <Link to={t("link.admin")} aria-label="admin">
                  <RiUserSettingsFill />
                </Link>
              )}
            </div>
            {getItem("user_token") ? (
              <div className="header__btn" onClick={() => handleLogOut()}>
                {t("btn.logOut")}
              </div>
            ) : (
              <Link
                to={t("link.register")}
                className="header__btn"
                style={{ marginLeft: "58px" }}>
                <span>{t("header.join")}</span>
              </Link>
            )}
          </div>
          {menuMobileIsOpen ? (
            <div
              className="header__hamburger"
              onClick={() => setMenuMobileIsOpen(!menuMobileIsOpen)}>
              <BiX size={40} />
            </div>
          ) : (
            <div className="header__hamburger" onClick={() => handleMenuClose()}>
              <BiMenu size={40} />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
